<template>
  <a class="select-box" @click.stop="changeOpen">
    <a-select v-bind="$attrs" class="edit-select" option-label-prop="label" :default-value="defaultValue"
      :open="selectOpen" @blur="blurEvent">
      <a-select-option v-for="(item, idx) in nowItems" :key="idx" :value="item.value" :label="item.value">
        <template v-if="item.edit">
          <div class="select-item edit-item" @click="stopPropagation">
            <a-input id="add-input" class="content" v-model="needEditItems[item.id].value" placeholder="请输入添加内容"
              @mousedown="stopPropagation" @click="stopPropagation" @blur="blurEvent" />
            <a-button class="check" icon="check" @click="(e) => confirmChangeItem(e, idx)" />
            <a-button icon="close" class="remove" @click="(e) => cancelChangeItem(e, idx)" />
          </div>
        </template>
        <template v-else>
          <div class="select-item" @click="selectItem(idx)">
            <div class="content">{{ item.value }}</div>
            <a-button icon="edit" @click="(e) => toEidtItem(e, idx)" />
            <a-button class="remove" icon="close" @click="(e) => removeItem(e, idx)" />
          </div>
        </template>
      </a-select-option>
      <div slot="dropdownRender" slot-scope="menu">
        <v-nodes :vnodes="menu" />
        <a-divider style="margin: 4px 0" />
        <div class="edit-row">
          <a-input id="change-input" v-model="eidtItem.value" placeholder="请输入修改内容" @mousedown="stopPropagation"
            @click="stopPropagation" @blur="blurEvent" />
          <a-button icon="plus" @click="addItem">添加</a-button>
        </div>
      </div>
    </a-select>
  </a>
</template>
<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
export default {
  components: {
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    defaultValue: {
      type: String,
      default: 'lucy',
    },
  },
  data: () => ({
    selectOpen: false,
    nowItems: [
      { id: 1, value: 'lucy' },
      { id: 2, value: 'tom' },
    ],
    eidtItem: {
      value: '',
    },
    needEditItems: {},
  }),
  watch: {
    selectOpen(val) {
      if (val) {
      } else {
      }
    },
  },
  created() {
    this.addEditItemSelect(this)
    console.log('edit-item-select', this);
    let needEditItems = {}
    let arr = this.items.map((item) => {
      needEditItems[item.id] = { ...item }
      return {
        ...item,
        edit: false,
      }
    })
    this.nowItems = arr
    this.needEditItems = needEditItems
  },
  methods: {
    ...mapMutations(['addEditItemSelect']),
    changeOpen(e) {
      this.selectOpen = !this.selectOpen
    },
    closeOpen() {
      this.selectOpen = false
    },
    blurEvent(e) {
      console.log('失去焦点，当前获得焦点元素：', document.activeElement)
    },
    focusEvent(e) { },
    stopPropagation(e) {
      e.stopPropagation()
    },
    addItem(e) {
      this.stopPropagation(e)
      // 这里需要将父组件中获取的新数据项添加到数组中
      if (['', null, undefined].includes(this.eidtItem.value?.trim())) {
        return
      }
      let item = {
        edit: false,
        id: Math.random(),
        value: this.eidtItem.value,
      }
      this.nowItems.push(item)
      this.needEditItems[item.id] = { ...item }
      this.eidtItem.value = ''
    },
    removeItem(e, idx) {
      this.stopPropagation(e)
      let removeItem = this.nowItems[idx]
      let newItems = this.nowItems.filter((item) => item.id !== removeItem.id)
      delete this.needEditItems[removeItem.id]
      this.nowItems = newItems
      this.needEditItems = { ...this.needEditItems }
    },
    confirmChangeItem(e, idx) {
      this.stopPropagation(e)
      this.nowItems[idx].value = this.needEditItems[this.nowItems[idx].id].value
      this.nowItems[idx].edit = false
    },
    cancelChangeItem(e, idx) {
      this.stopPropagation(e)
      let item = this.nowItems[idx]
      this.needEditItems[item.id].value = item.value
      this.nowItems[idx].edit = false
    },
    toEidtItem(e, idx) {
      this.stopPropagation(e)
      this.nowItems[idx].edit = true

      // 将当前需要编辑的数据放入 needEditItems
      this.needEditItems[this.nowItems[idx].id] = {
        ...this.nowItems[idx],
      }
    },
    selectItem(idx) {
      console.log('选择：', { ...this.nowItems[idx] })
      this.closeOpen()
    },
  },
}
</script>
<style scoped lang="less">
.select-box {
  position: relative;
}

.edit-select {
  width: 100%;
}

.edit-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;

  button {
    border: none;
  }
}

.select-item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .content {
    flex: 1;
  }

  button {
    border: none;
    background: none;
    width: unset;
    height: unset;
    line-height: unset;
    font-size: unset;
    margin-left: 10px;

    &:hover {
      background: none;
    }
  }

  .remove {
    &:hover {
      color: #f5222d;
    }
  }
}

.edit-item {
  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.ant-select-selection__rendered {
  button {
    display: none;
  }
}
</style>
