var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "select-box",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.changeOpen.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "a-select",
        _vm._b(
          {
            staticClass: "edit-select",
            attrs: {
              "option-label-prop": "label",
              "default-value": _vm.defaultValue,
              open: _vm.selectOpen,
            },
            on: { blur: _vm.blurEvent },
            scopedSlots: _vm._u([
              {
                key: "dropdownRender",
                fn: function (menu) {
                  return _c(
                    "div",
                    {},
                    [
                      _c("v-nodes", { attrs: { vnodes: menu } }),
                      _c("a-divider", { staticStyle: { margin: "4px 0" } }),
                      _c(
                        "div",
                        { staticClass: "edit-row" },
                        [
                          _c("a-input", {
                            attrs: {
                              id: "change-input",
                              placeholder: "请输入修改内容",
                            },
                            on: {
                              mousedown: _vm.stopPropagation,
                              click: _vm.stopPropagation,
                              blur: _vm.blurEvent,
                            },
                            model: {
                              value: _vm.eidtItem.value,
                              callback: function ($$v) {
                                _vm.$set(_vm.eidtItem, "value", $$v)
                              },
                              expression: "eidtItem.value",
                            },
                          }),
                          _c(
                            "a-button",
                            {
                              attrs: { icon: "plus" },
                              on: { click: _vm.addItem },
                            },
                            [_vm._v("添加")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                },
              },
            ]),
          },
          "a-select",
          _vm.$attrs,
          false
        ),
        _vm._l(_vm.nowItems, function (item, idx) {
          return _c(
            "a-select-option",
            { key: idx, attrs: { value: item.value, label: item.value } },
            [
              item.edit
                ? [
                    _c(
                      "div",
                      {
                        staticClass: "select-item edit-item",
                        on: { click: _vm.stopPropagation },
                      },
                      [
                        _c("a-input", {
                          staticClass: "content",
                          attrs: {
                            id: "add-input",
                            placeholder: "请输入添加内容",
                          },
                          on: {
                            mousedown: _vm.stopPropagation,
                            click: _vm.stopPropagation,
                            blur: _vm.blurEvent,
                          },
                          model: {
                            value: _vm.needEditItems[item.id].value,
                            callback: function ($$v) {
                              _vm.$set(_vm.needEditItems[item.id], "value", $$v)
                            },
                            expression: "needEditItems[item.id].value",
                          },
                        }),
                        _c("a-button", {
                          staticClass: "check",
                          attrs: { icon: "check" },
                          on: { click: (e) => _vm.confirmChangeItem(e, idx) },
                        }),
                        _c("a-button", {
                          staticClass: "remove",
                          attrs: { icon: "close" },
                          on: { click: (e) => _vm.cancelChangeItem(e, idx) },
                        }),
                      ],
                      1
                    ),
                  ]
                : [
                    _c(
                      "div",
                      {
                        staticClass: "select-item",
                        on: {
                          click: function ($event) {
                            return _vm.selectItem(idx)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "content" }, [
                          _vm._v(_vm._s(item.value)),
                        ]),
                        _c("a-button", {
                          attrs: { icon: "edit" },
                          on: { click: (e) => _vm.toEidtItem(e, idx) },
                        }),
                        _c("a-button", {
                          staticClass: "remove",
                          attrs: { icon: "close" },
                          on: { click: (e) => _vm.removeItem(e, idx) },
                        }),
                      ],
                      1
                    ),
                  ],
            ],
            2
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }